import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { HiArrowLeft } from 'react-icons/hi'
import Img from 'gatsby-image'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import ContactList from '../components/ContactList'
import img from '../images/contact-example.png'

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query ContactPageQuery {
			wordpressPage(
				slug: {
					eq: "vill-du-veta-mer-om-projektet-eller-har-du-forslag-och-ideer-som-kan-hjalpa-oss-bli-battre-hor-av-dig"
				}
			) {
				id
				title
				content
				featured_media {
					localFile {
						childImageSharp {
							fluid(maxWidth: 300, quality: 100) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
				acf {
					lista {
						titel
						content
					}
					bottom_text {
						content
						titel
						bild {
							localFile {
								childImageSharp {
									fluid(maxWidth: 300, quality: 100) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}
			}
		}
	`)

	return (
		<Layout>
			<div className="bg-green-bg w-full">
				<div className="max-w-screen-2xl md:px-6 lg:px-10 xl:px-20 flex justify-between items-center py-2 mx-auto">
					<div className="container mx-auto">
					<div className="w-auto block px-6 md:px-0">
						<button
							className="text-green-accent text-previous font-bold underline mt-8 flex items-center focus:outline-none"
							onClick={() => navigate(-1)}
						>
							<HiArrowLeft className="mr-2" />
							Gå tillbaka
						</button>
					</div>
						<SEO title="Kontakt" metaTitle='Business Halland' description='Business Halland gör det enklare för dig som företagare att hitta och ta del av de erbjudan och evenemang i Halland.' />
						<div
							className={
								'w-full px-6 bg-green-accent text-white py-16 md:px-0 md:bg-transparent ' +
								'md:text-black md:flex md:items-center'
							}
						>
							<div className="md:w-1/2">
								<h1 className="text-3xl leading-tight font-bold z-20 pb-4 md:text-green-accent lg:text-4xl xl:text-5xl">
									{data.wordpressPage.title}
								</h1>
								<StyledArticle className="w-full sm:text-left md:pr-16 lg:pr-32">
									{ReactHtmlParser(
										data.wordpressPage.content
									)}
								</StyledArticle>
							</div>
							<div className="hidden md:block w-1/2 relative">
								<div className="rounded-md z-10 relative rounded-img">
									<Img
										fluid={
											data.wordpressPage.featured_media
												.localFile.childImageSharp.fluid
										}
									/>
								</div>
							</div>
						</div>
						<div className="bg-green-bg py-4 md:flex md:justify-between md:space-x-4 md:py-10 lg:space-x-24">
							{data.wordpressPage.acf.lista &&
								data.wordpressPage.acf.lista.map((el, idx) => (
									<ContactList
										title={el.title}
										content={el.content}
										key={idx}
									/>
								))}
						</div>
						<div className="py-12 px-6 md:bg-white md:mb-10 md:rounded-xl md:shadow-sm md:flex md:px-10 lg:px-20 lg:mt-10 lg:mb-20">
							<div className="md:w-2/3 md:flex md:flex-col md:justify-between">
								<div
									className={
										'text-green-accent text-3xl font-bold leading-7 pb-6 md:text-black ' +
										'lg:leading-none lg:text-5xl'
									}
								>
									{data.wordpressPage.acf.bottom_text.titel}
								</div>
								<StyledArticle className="w-full md:text-left md:pr-4">
									{ReactHtmlParser(
										data.wordpressPage.acf.bottom_text
											.content
									)}
								</StyledArticle>
								<div className="w-full flex justify-center items-center pt-8 md:justify-start">
									<a
										href="mailto:Jenny.Blomedal@regionhalland.se"
										className={
											'bg-green-accent hover:bg-green-accent-dark ease-in duration-300 text-white w-10/12 text-center rounded-xl ' +
											'text-lg py-3 md:w-64'
										}
									>
										Kontakta oss
									</a>
								</div>
							</div>
							<div className="hidden md:flex md:w-1/3 max-h-21 justify-end">
								<img
									src={img}
									alt="img"
									className="object-contain h-full"
								/>
							</div>
						</div>
						<div className="h-20 bg-green-bg md:hidden"></div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

const StyledArticle = styled.article`
	> h1 {
		${tw`font-semibold text-2xl mt-12 mb-3`};
	}

	> h2 {
		${tw`font-semibold text-2xl mt-12 mb-3`};
	}

	> h3 {
		${tw`font-semibold text-xl mt-12 mb-3`};
	}

	> h4,
	h5 {
		${tw`font-semibold text-lg mt-8 mb-3`};
	}

	> p {
		${tw`text-lg mb-3 lg:text-xl`};
		a {
			${tw`text-green-accent font-bold underline`};
		}
	}

	> blockquote {
		${tw`my-12 text-center max-w-xl mx-auto`}

		p {
			${tw`font-semibold text-2xl md:text-3xl my-6`}
		}

		cite {
			${tw`not-italic`}
		}
	}

	> ul {
		${tw`mb-2 py-4 font-bold`}
		li {
			${tw`text-xl md:text-xl mb-2 pl-5 font-bold relative`}
			&:before {
				content: '';
				${tw`h-2 w-2 rounded-full bg-green-accent left-0 top-0 mt-3 absolute block`}
			}
			a {
				${tw`text-green-accent font-bold underline`};
			}
		}
	}

	> div {
		${tw`mt-12 w-full max-w-full`}
		img {
			${tw`w-full max-w-full`}
		}
	}

	> img {
		${tw`mt-12 w-full max-w-full`}
	}

	> figure {
		${tw`mt-12 w-full max-w-full`}
		img {
			${tw`w-full max-w-full`}
		}
		figcaption {
			${tw`my-2 text-sm`}
		}
	}
`

export default IndexPage
