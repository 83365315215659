import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const ContactList = ({title, content}) => (
  <div className='p-6 md:w-1/2 md:h-auto md:p-0'>
    <div className='text-center text-2xl font-bold leading-7 pb-4 md:hidden'>{title}</div>
    <StyledList className='bg-white shadow-sm rounded-lg p-4 arrow-after lg:px-10 lg:pb-12 lg:pt-6 md:h-full'>
      <div className='text-2xl font-bold leading-7 pb-4 hidden md:block'>{title}</div>
      {ReactHtmlParser(content)}
    </StyledList>
  </div>
)

const StyledList = styled.article`
	> p {
		${tw`w-full`}
    a {
      ${tw`underline hover:text-green-accent md:no-underline text-sm pt-2 lg:text-lg md:pt-1 relative block`};
    }
	}
`

ContactList.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string,
}

export default ContactList
